jQuery(function ($) {

  var $window = $(window);

  $window.scroll(function () {
    if ($window.scrollTop() > 50) {
      $('#header-bar').addClass("bar-sticky shadow");
    } else {
      $('#header-bar').removeClass("bar-sticky shadow");
    }
  });

  $(document).on('click', '[data-lightbox]', lity);

  $('.popular-carousel').slick({
    dots: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    prevArrow: '<button type="button" class="slick-prev" aria-label="Previous"><i class="bi bi-chevron-left"></i></button>',
    nextArrow: '<button type="button" class="slick-next" aria-label="Next"><i class="bi bi-chevron-right"></i></button>',
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  });

  $('.blog-carousel').slick({
    dots: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    prevArrow: '<button type="button" class="slick-prev" aria-label="Previous"><i class="bi bi-chevron-left"></i></button>',
    nextArrow: '<button type="button" class="slick-next" aria-label="Next"><i class="bi bi-chevron-right"></i></button>',
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  });

  $('.testimony-carousel').slick({
    arrows: true,
    dots: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 6000,
    prevArrow: '<button type="button" class="slick-prev" aria-label="Previous"><i class="bi bi-chevron-left"></i></button>',
    nextArrow: '<button type="button" class="slick-next" aria-label="Next"><i class="bi bi-chevron-right"></i></button>',
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 767,
        settings: {
          arrows: false,
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  });
  $('.timeline-carousel').slick({
    arrows: true,
    dots: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 3000,
    prevArrow: '<button type="button" class="slick-prev" aria-label="Previous"><i class="bi bi-chevron-left"></i></button>',
    nextArrow: '<button type="button" class="slick-next" aria-label="Next"><i class="bi bi-chevron-right"></i></button>',
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  });

  $('.parceiro-carousel').slick({
    dots: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    prevArrow: '<button type="button" class="slick-prev" aria-label="Previous"><i class="bi bi-chevron-left"></i></button>',
    nextArrow: '<button type="button" class="slick-next" aria-label="Next"><i class="bi bi-chevron-right"></i></button>',
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  });
  $('.cliente-carousel').slick({
    dots: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    prevArrow: '<button type="button" class="slick-prev" aria-label="Previous"><i class="bi bi-chevron-left"></i></button>',
    nextArrow: '<button type="button" class="slick-next" aria-label="Next"><i class="bi bi-chevron-right"></i></button>',
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  });
  $('.challenge-carousel').slick({
    arrows: false,
    dots: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    prevArrow: '<button type="button" class="slick-prev" aria-label="Previous"><i class="bi bi-chevron-left"></i></button>',
    nextArrow: '<button type="button" class="slick-next" aria-label="Next"><i class="bi bi-chevron-right"></i></button>'
  });
  $('#btn-cat').on('click', function(e){
    e.preventDefault();
    $('.categories-menu ul.menu').slideToggle();
  });

});